import type { IClientConfig } from '@spikemark/core';
import type { MergeDeep } from 'type-fest';

export type IServerConfig = MergeDeep<
  Omit<IClientConfig, 'baseUrl' | 'supabase' | 'mixpanel' | 'stripe'>,
  {
    environment: string;
    restApiBaseUrl: string;
    appBaseUrl: string;
    version: string;
    sharpPath: string;
    cacheToken: string;
    elastic: {
      endpoint: string;
      credentials: {
        username: string;
        password: string;
      };
      logResults: boolean;
    };
    openWeather: {
      apiKey: string;
    };
    launchDarkly: {
      sdkKey: string;
    };
    coachPortalUrl: string;
  }
>;

export const ServerConfig: IServerConfig = {
  environment: process.env.ENVIRONMENT || 'local',
  appBaseUrl: process.env.NEXT_PUBLIC_SITE_URL ?? '',
  restApiBaseUrl: process.env.REST_API_BASE_URL ?? '',
  version: process.env.PROJ_VERSION ?? 'local',
  disableCache: process.env.DISABLE_CACHE === 'true',
  cacheRevalidate: parseInt(process.env.CACHE_REVALIDATE ?? '1800', 10),
  launchDarkly: {
    sdkKey: process.env.LAUNCHDARKLY_SDK_KEY ?? '',
  },
  sharpPath: process.env.NEXT_SHARP_PATH ?? '',
  cloudinary: {
    basePath: process.env.CLOUDINARY_BASE_PATH ?? '',
  },
  cacheToken: process.env.CACHE_TOKEN ?? '',
  googleAnalytics: {
    gTag: process.env.GOOGLE_ANALYTICS_GTAG ?? '',
  },
  fetchRequestTimeout: parseInt(process.env.FETCH_REQUEST_TIMEOUT ?? '30000', 10),
  fetchRequestMaxRetries: parseInt(process.env.FETCH_REQUEST_MAX_RETRIES ?? '5', 10),
  elastic: {
    endpoint: process.env.ELASTIC_NODE_ENDPOINT as string,
    credentials: {
      username: process.env.ELASTIC_NODE_USERNAME as string,
      password: process.env.ELASTIC_NODE_PASSWORD as string,
    },
    logResults: false,
  },
  openWeather: {
    apiKey: process.env.OPEN_WEATHER_API_KEY as string,
  },
  coachPortalUrl: process.env.COACH_PORTAL_URL ?? '',
  scoreboardLiveLearnMoreUrl: process.env.SCOREBOARD_LIVE_LEARN_MORE_URL ?? '',
  helpCentreUrl: process.env.HELP_CENTRE_URL ?? '',
};
